// sections
import { AccountView } from "../../../components/account/view";

export const metadata = {
  title: "Dashboard: Account Settings",
};

export default function AccountPage() {
  return <AccountView />;
}
