import { useMemo } from "react";
import SvgColor from "../../components/svg-color";

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: icon("ic_user"),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      {
        subheader: "blocks",
        items: [
          {
            title: "blocks",
            path: "/dashboard/home",
            icon: ICONS.user,
          },
        ],
      },
    ],
    []
  );

  return data;
}
