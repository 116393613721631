import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
function Index() {
  const { isLoggedIn } = useContext(AuthContext);

  if (isLoggedIn) {
    return <Navigate to="/dashboard/home" />;
  } else {
    return <Navigate to="/login" />;
  }
}

export default Index;
