// TO GET THE USER FROM THE AUTHCONTEXT, YOU CAN USE

// CHANGE:
// import { useMockedUser } from 'src/hooks/use-mocked-user';
// const { user } = useMockedUser();

// TO:
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";

// ----------------------------------------------------------------------

export function useMockedUser() {
  const { user: user1 } = useContext(AuthContext);
  const user2 = user1();
  const user = {
    id: "8864c717-587d-472a-929a-8e5f298024da-0",
    displayName: user2?.username,
    email: user2?.email,
    photoURL: "/assets/avatar/avatar.jpg",
  };

  return { user };
}
