// components/PrivateRoute.js
import { Button, Link } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import DashboardLayout from "../../layouts/dashboard";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn, getContactLink } = useContext(AuthContext);
  const [linkData, setLinkData] = useState(null);
  const openContactLink = useCallback((link) => {
    if (link) {
      window.open(link, "_blank", "noopener,noreferrer");
    }
  }, []);

  useEffect(() => {
    const getContact = async () => {
      const linkData = await getContactLink();
      setLinkData(linkData);
    };
    getContact();
  }, [getContactLink]);

  return isLoggedIn ? (
    <>
      <DashboardLayout>
        <Component {...rest} />
        <div className="main-contact">
          <Link
            component={Button}
            size="large"
            color="inherit"
            variant="contained"
            onClick={() => openContactLink(linkData)}
          >
            Contact Us
          </Link>
        </div>
      </DashboardLayout>
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
