import React, { createContext, useCallback, useState } from "react";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token"));

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/user/login`, {
        email,
        password,
      });
      if (response?.status === 200) {
        const { token, username, email } = response?.data;
        const user = { username, email };
        localStorage.setItem("token", token);
        localStorage.setItem("userInfo", JSON.stringify(user));
        setIsLoggedIn(true);
        return true;
      } else {
        throw response?.data?.message;
      }
    } catch (error) {
      throw error?.response?.data?.message || "Authentication failed";
    }
  };

  const register = async (email, password, name) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/user/signup`, {
        email,
        password,
        name,
      });
      if (response?.status === 201) {
        const { token, username, email } = response?.data;
        const user = { username, email };
        localStorage.setItem("token", token);
        localStorage.setItem("userInfo", JSON.stringify(user));
        setIsLoggedIn(true);
        return true;
      } else {
        throw response?.data?.message;
      }
    } catch (error) {
      throw error?.response?.data?.message || "Failed to Register";
    }
  };

  const updateApi = useCallback(async (newData, token) => {
    try {
      const endpoint = `${API_BASE_URL}/api/user/updateUser`;

      const response = await axios.patch(endpoint, newData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.status === 200) {
        return true;
      } else {
        throw new Error("Failed to Update");
      }
    } catch (error) {}
  }, []);

  const user = () => {
    const userData = {
      ...JSON.parse(localStorage.getItem("userInfo")),
      token: localStorage.getItem("token"),
    };
    isUserLock(userData?.token);
    return userData;
  };

  const userInternal = () => {
    const userData = {
      ...JSON.parse(localStorage.getItem("userInfo")),
      token: localStorage.getItem("token"),
    };
    return userData;
  };

  const changePassword = async (data, token) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/user/change-password`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status === 200) {
        return "Password Updated Successfully";
      } else {
        throw new Error(response?.data?.message);
      }
    } catch (error) {
      throw new Error(
        error?.response?.data?.message || "Authentication failed"
      );
    }
  };

  const getContactLink = useCallback(async () => {
    try {
      const endpoint = `${API_BASE_URL}/api/user/contact-link`;
      const response = await axios.get(endpoint);
      if (response?.status === 200) {
        return response?.data || null;
      } else {
        throw new Error("Failed to fetch settings");
      }
    } catch (error) {
      return null;
    } finally {
    }
  }, []);

  const logout = useCallback(() => {
    const user1 = userInternal();
    updateApi({ status: "offline" }, user1?.token);
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  }, [updateApi]);

  const isUserLock = useCallback(
    async (token) => {
      try {
        const endpoint = `${API_BASE_URL}/api/user/checkIsUserLocked`;

        const response = await axios.get(endpoint, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response?.status === 200) {
          if (response?.data?.isLocked) {
            logout();
          }
        } else {
          throw new Error("Failed to Update");
        }
      } catch (error) {}
    },
    [logout]
  );

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        user,
        register,
        changePassword,
        getContactLink,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
