import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import { motion, useSpring, useTransform } from "framer-motion";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import AuthContext from "../../../context/AuthContext";
import {
  COINS_OPTIONS,
  formatDate,
  getCoinColor,
  getFormattedNumber,
  getNumberSuffix,
  getRandomText,
} from "../../../utils";
import { ConfirmDialog } from "../../custom-dialog";
import { useBoolean } from "../../hooks/use-boolean";
import { useSnackbar } from "../../snackbar";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const API_BASE_URL_WS = process.env.REACT_APP_API_URL_WS;

export default function UserListView() {
  const confirm = useBoolean();
  const messageEndRef = useRef();
  const simulationEndRef = useRef();
  const { user, logout } = useContext(AuthContext);
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [checkedCount, setCheckedCount] = useState(0);
  const [isBlockChainSettingsLoading, setIsBlockChainSettingsLoading] =
    useState(false);
  const [blockChainSettings, setBlockChainSettings] = useState(null);
  const [messages, setMessages] = useState([]);
  const [simulationMessages, setSimulationMessages] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedSimulation, setSelectedSimulation] = useState(null);
  let spring = useSpring(0, { mass: 0.8, stiffness: 75, damping: 15 });
  let display = useTransform(spring, (current) =>
    Math.round(current).toLocaleString()
  );

  const getMessagesApi = useCallback(async () => {
    try {
      setIsMessageLoading(true);
      const endpoint = `${API_BASE_URL}/api/message/messages`;
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${user()?.token}`,
        },
      });
      if (response?.status === 200) {
        setMessages((el) => response?.data || []);
      } else {
        throw new Error("Failed to fetch messages");
      }
    } catch (error) {
      enqueueSnackbar("Failed to fetch messages. Please try again later.", {
        variant: "error",
      });
    } finally {
      setIsMessageLoading(false);
    }
  }, [user, enqueueSnackbar]);

  const getBlockChainSettings = useCallback(async () => {
    try {
      setIsBlockChainSettingsLoading(true);
      const endpoint = `${API_BASE_URL}/api/blockchain/get/user`;
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${user()?.token}`,
        },
      });
      if (response?.status === 200) {
        setBlockChainSettings(response?.data || null);
      } else {
        throw new Error("Failed to fetch settings");
      }
    } catch (error) {
      enqueueSnackbar("Failed to fetch settings. Please try again later.", {
        variant: "error",
      });
    } finally {
      setIsBlockChainSettingsLoading(false);
    }
  }, [user, enqueueSnackbar]);

  useEffect(() => {
    const getMessages = async () => {
      await getMessagesApi();
    };
    const getSettings = async () => {
      await getBlockChainSettings();
    };
    getSettings();
    getMessages();
  }, [getMessagesApi, getBlockChainSettings]);

  const updateApi = useCallback(
    async (id, newData, type) => {
      try {
        let endpoint;
        if (type === "user") {
          endpoint = `${API_BASE_URL}/api/user/updateUser`;
        } else if (type === "blockchain") {
          endpoint = `${API_BASE_URL}/api/blockchain/updateUSerBlockChain/${id}`;
        } else {
          return;
        }

        const response = await axios.patch(endpoint, newData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user()?.token}`,
          },
        });
        if (response?.status === 200) {
          if (type === "user") {
            return true;
          } else {
            // setBlockChainSettings(response?.data || null);
          }
        } else {
          throw new Error("Failed to Update");
        }
      } catch (error) {
        enqueueSnackbar("Failed to update. Please try again later.", {
          variant: "error",
        });
      }
    },
    [user, enqueueSnackbar]
  );

  const handleClick = useCallback(
    (index) => {
      if (!blockChainSettings) {
        enqueueSnackbar("Cant Run Simulations. No Settings Available", {
          variant: "error",
        });
        return;
      } else if (index > 3 && blockChainSettings?.option === "B") {
        enqueueSnackbar("You can only search for the first 4 coins", {
          variant: "error",
        });
        return;
      } else if (index === selectedSimulation) {
        updateApi(blockChainSettings?._id, { running: "None" }, "blockchain");
        setSelectedSimulation(null);
      } else {
        updateApi(
          blockChainSettings?._id,
          {
            running:
              index === -1 && blockChainSettings?.option === "B"
                ? "First Four"
                : index === -1
                ? "All"
                : index === null
                ? "None"
                : `${COINS_OPTIONS?.[index]?.name}`,
          },
          "blockchain"
        );
        setSelectedSimulation(index);
      }
    },
    [
      blockChainSettings,
      enqueueSnackbar,
      setSelectedSimulation,
      selectedSimulation,
      updateApi,
    ]
  );

  // useEffect(() => {
  //   // Establish WebSocket connection when component mounts
  //   const ws = new WebSocket(`${API_BASE_URL_WS}?token=${user()?.token}`);

  //   // Define event listeners
  //   ws.onopen = () => {
  //     console.log("WebSocket connected");
  //   };

  //   ws.onmessage = (event) => {
  //     const message = JSON.parse(event?.data);
  //     if (message?.type === "update_lock") {
  //       if (message?.data?.locked) {
  //         enqueueSnackbar("You are locked by Admin", {
  //           variant: "error",
  //         });
  //         logout();
  //       }
  //     } else if (message?.type === "update_delete") {
  //       enqueueSnackbar("Your Account Has Been Deleted", {
  //         variant: "error",
  //       });
  //       logout();
  //     } else if (message?.type === "update_blockchain") {
  //       setSelectedSimulation(null);
  //       setBlockChainSettings((previous) => {
  //         updateApi(previous?._id, { running: "None" }, "blockchain");
  //         return {
  //           ...previous,
  //           ...message?.data,
  //         };
  //       });
  //       enqueueSnackbar("Settings Updated By Admin", {
  //         variant: "success",
  //       });
  //     } else if (message?.type === "update_message") {
  //       setMessages((previos) => [...previos, message?.data]);
  //     }
  //   };

  //   ws.onclose = () => {
  //     console.log("WebSocket disconnected");
  //   };
  // }, [user, logout, enqueueSnackbar, updateApi]);

  const scrollToBottom = () => {
    // messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
    const scrollContainer = messageEndRef.current?.parentNode;
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  };

  const scrollToBottomSimulation = () => {
    // simulationEndRef.current?.scrollIntoView({ behavior: "smooth" });
    const scrollContainer = simulationEndRef.current?.parentNode;
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 0);
  }, [messages]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     scrollToBottomSimulation();
  //   }, parseInt(blockChainSettings?.speed?.split("/")?.[0] || 0));
  // }, [simulationMessages, blockChainSettings]);

  useEffect(() => {
    const fetchData = async () => {
      if (!blockChainSettings) return;
      if (selectedSimulation == null) return;

      await updateApi(
        blockChainSettings._id,
        {
          timer: 1,
        },
        "blockchain"
      );
    };

    fetchData();

    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, [blockChainSettings, updateApi, selectedSimulation]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (!blockChainSettings) return;
  //     if (selectedSimulation == null) return;
  //     setSimulationMessages((prevMessages) => {
  //       const updatedMessages = [...prevMessages];
  //       for (
  //         let i = 0;
  //         i < parseInt(blockChainSettings?.speed?.split("/")?.[0]);
  //         i++
  //       ) {
  //         updatedMessages.push(
  //           Math.random()
  //             .toString(36)
  //             .substring(Math.ceil(Math.random() * (10 - 1) + 1))
  //         );
  //       }
  //       return updatedMessages.slice(-50);
  //     });
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, [blockChainSettings, selectedSimulation]);

  useEffect(() => {
    let isMounted = true;
    const runningSpeed = parseInt(blockChainSettings?.speed?.split("/")?.[0]);
    const countSpeed = runningSpeed === 20 ? 50 : 100;

    const updateMessages = async () => {
      if (!blockChainSettings || selectedSimulation == null) return;

      setSimulationMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        for (let i = 0; i < runningSpeed; i++) {
          updatedMessages.push(getRandomText(50));
        }
        return updatedMessages.slice(-30);
      });
      scrollToBottomSimulation();

      if (isMounted) {
        setTimeout(updateMessages, 50);
      }
    };

    const updateMessages2 = async () => {
      if (!blockChainSettings || selectedSimulation == null) return;
      setCheckedCount((previousCount) => previousCount + countSpeed);

      if (isMounted) {
        setTimeout(updateMessages2, 1000);
      }
    };

    updateMessages();
    updateMessages2();

    return () => {
      isMounted = false; // Cleanup flag on unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockChainSettings, selectedSimulation]);

  useEffect(() => {
    spring.set(getFormattedNumber(checkedCount));
  }, [spring, checkedCount]);

  useEffect(() => {
    // Establish ReconnectingWebSocket connection when component mounts
    const url = `${API_BASE_URL_WS}?token=${user()?.token}`;
    const options = {
      connectionTimeout: 1000,
      maxRetries: Infinity,
      // debug: true,
    };
    const ws = new ReconnectingWebSocket(url, [], options);

    // Define event listeners
    ws.addEventListener("open", () => {
      console.log("WebSocket connected");
    });

    ws.addEventListener("message", (event) => {
      const message = JSON.parse(event?.data);
      if (message?.type === "update_lock") {
        if (message?.data?.locked) {
          enqueueSnackbar("You are locked by Admin", {
            variant: "error",
          });
          logout();
        }
      } else if (message?.type === "update_delete") {
        enqueueSnackbar("Your Account Has Been Deleted", {
          variant: "error",
        });
        logout();
      } else if (message?.type === "timer_message") {
        enqueueSnackbar(`Message: ${message?.data}`, {
          variant: "success",
        });
      } else if (message?.type === "delete_message") {
        setMessages((previosItem) =>
          previosItem?.filter((item) => item?._id !== message?.data?.id)
        );
      } else if (message?.type === "update_blockchain") {
        setSelectedSimulation(null);
        setBlockChainSettings((previous) => {
          updateApi(previous?._id, { running: "None" }, "blockchain");
          return {
            ...previous,
            ...message?.data,
          };
        });
        enqueueSnackbar("Settings Updated By Admin", {
          variant: "success",
        });
      } else if (message?.type === "update_message") {
        setMessages((previous) => [...previous, message?.data]);
      }
    });

    ws.addEventListener("close", () => {
      console.log("WebSocket disconnected");
    });

    return () => {
      ws.close();
    };
  }, [
    user,
    logout,
    enqueueSnackbar,
    updateApi,
    setBlockChainSettings,
    setSelectedSimulation,
    setMessages,
  ]);

  return (
    <>
      <Container maxWidth={"lg"} sx={{ zIndex: 1 }}>
        <Grid container spacing={3} disableEqualOverflow>
          <Grid item xs={12} md={6}>
            <>
              <Card>
                <CardHeader
                  // title={
                  //   blockChainSettings?.speed
                  //     ? `Simulation x ${blockChainSettings?.speed}`
                  //     : "Simulation"
                  // }
                  title={
                    <Grid container>
                      <Grid item xs={12} sm={8}>
                        Wallet Check
                      </Grid>
                      <Grid xs={12} sm={4} container>
                        <Grid xs={6} item>
                          Checked:
                        </Grid>
                        <Grid xs={getNumberSuffix(checkedCount) ? 2 : 5} item>
                          <motion.span>{display}</motion.span>
                        </Grid>
                        <Grid xs={0} item></Grid>
                        {getNumberSuffix(checkedCount)}
                      </Grid>
                    </Grid>
                  }
                  sx={{
                    textAlign: "center",
                    background: "#0d6efd",
                    color: "#fff",
                  }}
                />
                {simulationMessages?.length ? (
                  <Box
                    sx={{
                      height: 200,
                      overflowY: "auto",
                      background: "#0d6efd",
                      color: "#fff",
                      WebkitOverflowScrolling: "touch",
                      scrollBehavior: "smooth",
                    }}
                  >
                    <ul className="message-list" style={{ cursor: "default" }}>
                      {simulationMessages?.map((message, index) => (
                        <li
                          key={index}
                          className="message-list-item"
                          style={{ cursor: "default" }}
                        >
                          <span className="message-time">Wallet Check:</span>
                          <span>{message}</span>
                        </li>
                      ))}
                    </ul>
                    <div ref={simulationEndRef}></div>
                  </Box>
                ) : null}
                {!simulationMessages?.length && (
                  <Box
                    sx={{
                      height: 200,
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#0d6efd",
                      color: "#fff",
                    }}
                  >
                    <div>No Simulations</div>
                  </Box>
                )}
              </Card>
            </>
          </Grid>
          <Grid item xs={12} md={6}>
            <>
              <Card>
                <CardHeader title="Messages" sx={{ textAlign: "center" }} />
                {messages?.length && !isMessageLoading ? (
                  <Box
                    sx={{
                      height: 200,
                      overflowY: "auto",
                      background: "inherit",
                      WebkitOverflowScrolling: "touch",
                      scrollBehavior: "smooth",
                    }}
                  >
                    <ul className="message-list" style={{ cursor: "default" }}>
                      {messages?.map((message, index) => (
                        <li
                          key={index}
                          className="message-list-item"
                          style={{ cursor: "default" }}
                        >
                          <span className="message-time">{`${formatDate(
                            message?.createdAt
                          )}:`}</span>
                          <span>{message?.content}</span>
                        </li>
                      ))}
                    </ul>
                    <div ref={messageEndRef}></div>
                  </Box>
                ) : null}
                {isMessageLoading && (
                  <Box
                    sx={{
                      height: 200,
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "inherit",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                {!isMessageLoading && !messages?.length && (
                  <Box
                    sx={{
                      height: 200,
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "inherit",
                    }}
                  >
                    <div>No Messages</div>
                  </Box>
                )}
              </Card>
            </>
          </Grid>
          {COINS_OPTIONS?.map((coin, index) => (
            <Grid item xs={12} md={3}>
              <Card>
                <CardActionArea
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "1rem",
                    background: getCoinColor(
                      index,
                      selectedSimulation,
                      blockChainSettings?.option
                    )
                      ? getCoinColor(
                          index,
                          selectedSimulation,
                          blockChainSettings?.option
                        )
                      : "inherit",
                    // background: "inherit",
                  }}
                  onClick={() => {
                    handleClick(index);
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: 0,
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ marginBottom: 0 }}
                    >
                      {coin?.name}
                    </Typography>
                  </CardContent>
                  <CardMedia
                    component="img"
                    image={coin?.imageSrc}
                    alt={coin?.altText}
                    sx={{
                      objectFit: "contain",
                      width: "50px",
                      height: "50px",
                    }}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} md={4} sx={{ paddingTop: "2rem" }}>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <LoadingButton
              variant="contained"
              loading={false}
              color={selectedSimulation === -1 ? "error" : "primary"}
              onClick={confirm.onTrue}
              sx={{
                width: "fit-content",
                display: "inline-block",
              }}
            >
              {selectedSimulation === -1 ? "Stop All" : "Start All"}
            </LoadingButton>
          </div>
        </Grid>
      </Container>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Confirm Action"
        content={`Are you sure want to ${
          selectedSimulation === -1 ? "Stop All" : "Start All"
        }?`}
        action={
          <Button
            variant="contained"
            color={selectedSimulation === -1 ? "error" : "primary"}
            onClick={() => {
              handleClick(selectedSimulation === -1 ? null : -1);
              confirm.onFalse();
            }}
          >
            {selectedSimulation === -1 ? "Stop All" : "Start All"}
          </Button>
        }
      />
    </>
  );
}
