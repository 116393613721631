// sections
import { UserListView } from "../../../components/Users/List";

export const metadata = {
  title: "Dashboard: Product List",
};

export default function Index() {
  return <UserListView />;
}
