// App.js
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
// eslint-disable-next-line no-unused-vars
import ThemeProvider from "../src/theme";
import NonExist from "./components/404";
import PrivateRoute from "./components/PrivateRoutes/PrivateRoute";
import Dashboard from "./pages/Dashboard/Home";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import SnackbarProvider from "./components/snackbar/snackbar-provider";
import ChangePassword from "./pages/Dashboard/Account";
import ParticlesComponent from "./components/Particles/particles";
import { SettingsDrawer, SettingsProvider } from "./components/settings";
function App() {
  return (
    <AuthProvider>
      <Router>
        <SettingsProvider
          defaultSettings={{
            themeMode: "light", // 'light' | 'dark'
            themeDirection: "ltr", //  'rtl' | 'ltr'
            themeContrast: "default", // 'default' | 'bold'
            themeLayout: "horizontal", // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: "default", // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <SnackbarProvider>
              <SettingsDrawer />
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route
                  path="/dashboard/home"
                  element={<PrivateRoute component={Dashboard} />}
                />
                <Route
                  path="/dashboard/change-password"
                  element={<PrivateRoute component={ChangePassword} />}
                />
                <Route path="*" element={<NonExist />} />
              </Routes>
            </SnackbarProvider>
            <ParticlesComponent id="particles" />
          </ThemeProvider>
        </SettingsProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;
