import { Box, Link } from "@mui/material";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const navigate = useNavigate();
  const logo = (
    <Box
      component={"img"}
      src="/assets/logo/logo.webp"
      sx={{
        width: 50,
        height: 50,
        cursor: "pointer",
        ...sx,
      }}
    ></Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link
      sx={{ display: "contents" }}
      onClick={() => {
        navigate("/dashboard/home");
      }}
    >
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
