import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AuthContext from "../../context/AuthContext";
import FormProvider, { RHFTextField } from "../hook-form";
import { useBoolean } from "../hooks/use-boolean";
import Iconify from "../iconify";

function Login() {
  const { login, getContactLink } = useContext(AuthContext);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const password = useBoolean();
  const [linkData, setLinkData] = useState(null);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const result = await login(data.email, data.password);
      if (result) {
        navigate("/dashboard/home");
      }
    } catch (error) {
      setErrorMsg(typeof error === "string" ? error : error?.message);
    }
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant="h4">Sign In</Typography>
      {/* <Stack direction="row" spacing={0.5}>
        <Typography variant="body2">Dont Have Account?</Typography>

        <Link
          component="button"
          variant="subtitle2"
          onClick={() => {
            navigate("/signup");
          }}
        >
          Contact Admin
        </Link>
      </Stack> */}
    </Stack>
  );

  const openContactLink = useCallback((link) => {
    if (link) {
      window.open(link, "_blank", "noopener,noreferrer");
    }
  }, []);

  useEffect(() => {
    const getContact = async () => {
      const linkData = await getContactLink();
      setLinkData(linkData);
    };
    getContact();
  }, [getContactLink]);

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

      <RHFTextField name="email" label="User" />

      <RHFTextField
        name="password"
        label="Password"
        type={password.value ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify
                  icon={
                    password.value ? "solar:eye-bold" : "solar:eye-closed-bold"
                  }
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {/* <Link
        variant="body2"
        href={"/login"}
        color="inherit"
        underline="always"
        sx={{ alignSelf: "flex-end" }}
      >
        Forgot password?
      </Link> */}

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
      <Link
        component={Button}
        color="inherit"
        variant="contained"
        onClick={() => openContactLink(linkData)}
      >
        Contact Us
      </Link>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}
      {renderForm}
    </FormProvider>
  );
}

export default Login;
