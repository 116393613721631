export const USER_OPTIONS = ["A", "B"];

export const SPEED_OPTIONS = ["1000/sec", "3000/sec"];
export const COINS_OPTIONS = [
  {
    name: "Bitcoin",
    price: "$ 11,785.10",
    imageSrc: "/assets/coins/Bitcoin.png",
    altText: "Bitcoin Logo Image",
  },
  {
    name: "Cardano",
    price: "$ 5,785.10",
    imageSrc: "/assets/coins/Cardano.png",
    altText: "Cardano Logo Image",
  },
  {
    name: "Etherium",
    price: "$ 7,482.10",
    imageSrc: "/assets/coins/ETHERIUM.png",
    altText: "Etherium Logo Image",
  },
  {
    name: "Litecoin",
    price: "$ 2,683.00",
    imageSrc: "/assets/coins/LITECOIN.png",
    altText: "Litecoin Logo Image",
  },
  {
    name: "Monero",
    price: "$ 235.80",
    imageSrc: "/assets/coins/MONERO.png",
    altText: "Monero Logo Image",
  },
  {
    name: "Ripple",
    price: "$ 5,235.80",
    imageSrc: "/assets/coins/RIPPLE.png",
    altText: "Ripple Logo Image",
  },
  {
    name: "Solana",
    price: "$ 12,500.50",
    imageSrc: "/assets/coins/SOLANA.png",
    altText: "Solana Logo Image",
  },
  {
    name: "Tron",
    price: "$ 17,254.70",
    imageSrc: "/assets/coins/TRON.png",
    altText: "Tron Logo Image",
  },
];

export const getCheckedValue = (options, value, key, enabledKey) => {
  const val = options?.find((item) => item?.[key] === value?.[key]);
  if (val) {
    return val?.[enabledKey];
  }
};

export const formatDate = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const day = date?.getDate()?.toString()?.padStart(2, "0");
  const month = (date?.getMonth() + 1)?.toString()?.padStart(2, "0");
  const year = date?.getFullYear()?.toString()?.slice(2);
  const hour = date?.getHours() % 12 || 12;
  const minute = date?.getMinutes()?.toString()?.padStart(2, "0");
  const ampm = date?.getHours() < 12 ? "AM" : "PM";

  return `${day}-${month}-${year}, ${hour}:${minute} ${ampm}`;
};

export const getRandomString = () => {
  return Math.random().toString(36).substring(7);
};

export const getCoinColor = (index, selectedIndex, option) => {
  if (option === "B" && index > 3) {
    return false;
  } else if (selectedIndex === -1) {
    return "#989090";
  } else if (index === selectedIndex) {
    return "#989090";
  } else {
    return false;
  }
};

export function localStorageAvailable() {
  try {
    const key = "__some_random_key_you_are_not_going_to_use__";
    window.localStorage.setItem(key, key);
    window.localStorage.removeItem(key);
    return true;
  } catch (error) {
    return false;
  }
}

export function localStorageGetItem(key, defaultValue = "") {
  const storageAvailable = localStorageAvailable();

  let value;

  if (storageAvailable) {
    value = localStorage.getItem(key) || defaultValue;
  }

  return value;
}

export const getFormattedNumber = (num) => {
  if (num >= 1000000000000) {
    return parseFloat((num / 1000000000000).toFixed(1));
  }
  if (num >= 1000000000) {
    return parseFloat((num / 1000000000).toFixed(1));
  }
  return num;
};

export const getNumberSuffix = (num) => {
  if (num >= 1000000000000) {
    return "T";
  }
  if (num >= 1000000000) {
    return "B";
  }
  return "";
};

export const randomTextArray = [
  "twin vivid suffer eternal avocado wood people where ripple version pear jealous",
  "bless demise asset direct real arm advance mean snack middle coast rigid",
  "position cactus ring repeat amount bean forget stumble pyramid across safe word",
  "scout vacant explain hero attend dust mountain cargo old thought audit record",
  "budget amateur piano hundred bracket belt south transfer dream van fix motion",
  "sweet brush tennis fee offer day picnic slice loop sketch nerve amused",
  "opinion business beauty buyer age abandon bike sudden current tired all tackle",
  "coach tomato boost aware sample glance paddle artwork time symbol pause mix",
  "pumpkin token camera rally column tip tell path tribe cat salute cousin",
  "mammal excuse cousin scrub gain extra clump measure tattoo offer flush width",
  "combine lizard speak beach mistake picnic girl neck toilet impose atom offer",
  "adult coil that vendor child cradle bike hand spend fetch rice key",
  "direct category shiver skate drum afraid zone void layer unusual maple skin",
  "sense ski struggle wheel survey slogan planet dutch sunny inch force cool",
  "enemy either put kangaroo misery cradle search tank bicycle power wall cram",
  "cigar tag frog ketchup lava paper destroy adjust omit merge skate busy",
  "install define mystery visit dad text panel record provide breeze rug jewel",
  "unlock achieve analyst race differ rib shuffle fruit record plate mind catch",
  "current warfare rapid employ position vintage apple ripple junk shy high health",
  "cake hazard suggest refuse clump hello belt suggest clever cable guilt recycle",
  "review salon antenna zone globe cancel typical nice fluid avoid resemble velvet",
  "alarm trash fatal height dash episode pave rebel roof ozone focus slim",
  "unable mountain guilt manage surface tragic fuel umbrella razor fold air sock",
  "loyal prevent rely atom dutch apple expire latin enemy dirt thumb auto",
  "purchase train city lava true embrace field film flame mass whip course",
  "north picture pink pioneer material key forum often floor indicate effort corn",
  "ivory use scrap trap protect obvious foil earn need inhale element fragile",
  "boss rural penalty east genius have boring great toss remove zero planet",
  "bonus involve tornado vote donor boss buzz sister two elegant dizzy tuition",
  "gloom blast staff palace tooth novel citizen design adult double wide door",
  "reject argue seven candy nominee cup pizza kingdom real problem occur tuition",
  "write pretty talent pair hair wage wet service trigger demise desk ridge",
  "basket bubble chicken reward grape capital smoke decrease reduce deposit dynamic neutral",
  "pear update recall burst cost leader tuition rug degree because radar marble",
  "lab lemon smoke dismiss fashion area hobby melody supreme crowd chase club",
  "cook absurd two believe must horse insect scale skin jealous abstract magic",
  "obtain force relax index category surface scrap accuse random cabbage pet fee",
  "hurt option caution remove film wise shed inflict adult estate token menu",
  "summer usual vicious eight month glance mushroom mosquito biology fever scissors mystery",
  "long fee deny leisure curious horn daughter immense supreme cry casino swallow",
  "act hospital draft either swap toss lemon general jungle zero glass either",
  "enact diagram artwork zebra pull patient type clown gift imitate illness pitch",
  "delay wealth quarter under fish cat reflect ripple blanket dial mansion peanut",
  "agent note suit replace vast face popular imitate help poverty hard march",
  "dilemma host regret minimum include address output denial popular vanish muscle napkin",
  "case legal shuffle pistol town merge cargo bird force clown design merit",
  "deny stone apology jungle member speed finger update plastic upset muscle keep",
  "umbrella merge elder suffer industry can tree isolate crunch month visa fragile",
  "fruit scene clog know cradle civil six split world slogan build flip",
  "good stadium trumpet flip teach update pudding destroy long pretty install assume",
  "security sausage ketchup stamp trim garage ramp frog elder reveal sword target",
  "equip right spin burger beach tobacco donor clean immune main top immense",
  "jeans stone kiwi panic evil road orange lake crunch mobile inmate width",
  "question scout spike client ladder rapid bike feed group novel cluster infant",
  "slam tomorrow wink urge stomach subject scatter unfold panic property circle salute",
  "analyst buyer snow delay logic absent steel churn feature sense lecture arctic",
  "box midnight cover welcome police sell common sketch blossom harbor police adjust",
  "excite tape gadget knife proof battle desk assist raw cup puppy obey",
  "pyramid ask sample icon local spoon recall hill real effort power blind",
  "reveal mobile economy output okay ill purchase steel deal legend balcony poet",
  "hunt wear swing coconut electric expire cloud actress street charge steak cancel",
  "taste spell vocal talk author disagree inherit language gauge twice famous staff",
  "bronze clever aunt decline ready walnut menu stereo empower lazy penalty index",
  "joke innocent purity before sheriff become lottery repair prison winter put dance",
  "boy mobile cluster sadness person clip muffin eagle wink junior orange egg",
  "year wage slide acoustic host eager truly priority blade reason neither behave",
  "search prosper clown problem dragon light crack egg second dice pulp saddle",
  "series rather twelve soul taste powder penalty motion opinion rule step noodle",
  "list wood grunt wise candy unable satoshi artefact demise wait unhappy nothing",
  "mouse dumb sword bind cross despair donor alter autumn soft include slogan",
  "grit pipe lobster romance inhale home sign grass ivory lawn crystal island",
  "zero bounce candy spend worry sister wrestle blanket moment evolve obvious lens",
  "candy brand age blind topic width struggle traffic know noodle powder guard",
  "run barrel honey join design pipe shuffle crouch endless pear rain play",
  "major proud orbit spoil chair tomato surprise idea disorder brown vivid gain",
  "that impose catch moral van forward snake border slush panda river novel",
  "antique pretty define produce rubber copy window slender invite spike park distance",
  "jar ankle antenna draft seminar cloud girl armed soul fun ginger tool",
  "young toast humble stone feel auction salute also fatigue become sword food",
  "river garlic slogan roof moment safe rifle toy vacant grab ancient clinic",
  "solve replace elder title brother treat seven forward canvas wolf misery work",
  "drift wheat area mobile safe witness balance honey guard fee beyond steak",
  "network canvas illness rack airport uncle entire private spare exercise measure copper",
  "awesome volcano hockey hip tiny pony magnet find below stem change betray",
  "correct render zone juice inherit scout middle cigar hero endorse duty play",
  "energy disease milk pizza notice dose isolate sad ozone dentist scorpion where",
  "three truck luggage few bean excite athlete slam example bonus shoot bike",
  "annual shield mad example pigeon soul gloom pair resource shift creek shrug",
  "exit cloud display shuffle execute useless portion sport job room shell rabbit",
  "dwarf tissue lobster add assume spike deal office twist produce list design",
  "neutral crucial expect aware punch hobby route host boost under flag trophy",
  "lecture blush ridge pull truly goose repeat off length pizza quarter devote",
  "certain anxiety weapon robot slow timber december border brain grow orbit company",
  "coil scout head useless genre fog someone setup pact cupboard rather narrow",
  "tiny frame step issue loyal media lemon member update derive cream shy",
  "rhythm tenant laptop market globe ceiling night wrong chalk group cage learn",
  "fall craft soccer spoil peasant involve dog chest script furnace damage civil",
  "equip large lounge upset slab laptop face toast power trial about sweet",
  "confirm square detail borrow hurt welcome end power lamp perfect gold dose",
  "avoid design believe custom squirrel possible fix raise real van eight scorpion",
  "direct category shiver skate drum afraid zone void layer unusual maple skin",
  "sense ski struggle wheel survey slogan planet dutch sunny inch force cool",
  "enemy either put kangaroo misery cradle search tank bicycle power wall cram",
  "cigar tag frog ketchup lava paper destroy adjust omit merge skate busy",
  "install define mystery visit dad text panel record provide breeze rug jewel",
  "unlock achieve analyst race differ rib shuffle fruit record plate mind catch",
  "current warfare rapid employ position vintage apple ripple junk shy high health",
  "cake hazard suggest refuse clump hello belt suggest clever cable guilt recycle",
  "review salon antenna zone globe cancel typical nice fluid avoid resemble velvet",
  "major proud orbit spoil chair tomato surprise idea disorder brown vivid gain",
  "that impose catch moral van forward snake border slush panda river novel",
  "antique pretty define produce rubber copy window slender invite spike park distance",
  "jar ankle antenna draft seminar cloud girl armed soul fun ginger tool",
  "young toast humble stone feel auction salute also fatigue become sword food",
  "river garlic slogan roof moment safe rifle toy vacant grab ancient clinic",
  "solve replace elder title brother treat seven forward canvas wolf misery work",
  "twin vivid suffer eternal avocado wood people where ripple version pear jealous",
  "bless demise asset direct real arm advance mean snack middle coast rigid",
  "tiny frame step issue loyal media lemon member update derive cream shy",
  "rhythm tenant laptop market globe ceiling night wrong chalk group cage learn",
  "fall craft soccer spoil peasant involve dog chest script furnace damage civil",
  "equip large lounge upset slab laptop face toast power trial about sweet",
  "confirm square detail borrow hurt welcome end power lamp perfect gold dose",
  "avoid design believe custom squirrel possible fix raise real van eight scorpion",
  "north picture pink pioneer material key forum often floor indicate effort corn",
  "ivory use scrap trap protect obvious foil earn need inhale element fragile",
  "boss rural penalty east genius have boring great toss remove zero planet",
  "bonus involve tornado vote donor boss buzz sister two elegant dizzy tuition",
  "gloom blast staff palace tooth novel citizen design adult double wide door",
  "reject argue seven candy nominee cup pizza kingdom real problem occur tuition",
  "write pretty talent pair hair wage wet service trigger demise desk ridge",
  "basket bubble chicken reward grape capital smoke decrease reduce deposit dynamic neutral",
  "pear update recall burst cost leader tuition rug degree because radar marble",
  "lab lemon smoke dismiss fashion area hobby melody supreme crowd chase club",
  "cook absurd two believe must horse insect scale skin jealous abstract magic",
  "obtain force relax index category surface scrap accuse random cabbage pet fee",
  "hurt option caution remove film wise shed inflict adult estate token menu",
  "summer usual vicious eight month glance mushroom mosquito biology fever scissors mystery",
  "long fee deny leisure curious horn daughter immense supreme cry casino swallow",
  "act hospital draft either swap toss lemon general jungle zero glass either",
  "enact diagram artwork zebra pull patient type clown gift imitate illness pitch",
  "delay wealth quarter under fish cat reflect ripple blanket dial mansion peanut",
  "agent note suit replace vast face popular imitate help poverty hard march",
  "dilemma host regret minimum include address output denial popular vanish muscle napkin",
  "case legal shuffle pistol town merge cargo bird force clown design merit",
  "deny stone apology jungle member speed finger update plastic upset muscle keep",
  "umbrella merge elder suffer industry can tree isolate crunch month visa fragile",
  "fruit scene clog know cradle civil six split world slogan build flip",
  "dinosaur ripple stumble praise warfare matrix viable shed fruit undo winter jungle",
  "lamp knee move zero fat health champion clerk debate recipe noise shine",
  "crisp congress panda exact arrest cart muscle amazing waste snow clown practice",
  "share garlic rent obey hunt follow skull stem upgrade mad hat real",
  "credit fuel junk various guide major follow quantum month upon repair unique",
  "nice peace stage alert essence caution cat make below dice couch police",
  "tongue table steak top ghost angle nasty staff frog child cup cream",
  "cruise guess black nominee common multiply rival delay cram depart tuition genius",
  "wisdom prepare seat gallery nice core ramp outside isolate mushroom dice scheme",
  "mass evidence second relax web ceiling mask way away silk diet certain",
  "shrimp remind palm chase start pass foil term digital advice soldier lawn",
  "auto length organ solid gauge bamboo photo walk kiwi curious success moment",
  "donate auto effort wage employ absurd fresh hello fox behave service market",
  "evil enlist letter unknown bar animal length ecology dose recipe car loop",
  "onion erase ship silly bundle pepper clay lift collect empty pattern trim",
  "enter minor ethics affair easily dinner enroll funny spy claw coyote venture",
  "abstract bounce surprise engine surge wife better taxi pottery need album style",
  "engage suggest bean boat social hundred honey summer mule drive welcome name",
  "hint sort exhibit pioneer blue squirrel lift exercise napkin pipe favorite broken",
  "snack few action loud melt stumble mercy end indoor winter ramp response",
  "practice luggage food vast song tunnel comic mistake matter idle someone harbor",
  "debris coconut sell nature chunk route ecology dinner scale bronze identify orchard",
  "wheat allow middle path art depth deny sweet crash roast embark park",
  "actress prevent scene enhance diary rhythm build noble cliff wagon runway state",
  "mad reject strike vote egg lunar net budget recycle alley girl sponsor",
  "toast hazard axis ride lyrics hello citizen brick pyramid enough universe return",
  "main guide casino arrow verify public huge tag neither addict defense catch",
  "question lazy black surround code attend frequent rocket target evidence waste ivory",
  "alter addict wisdom evolve rain siege mirror plate adjust kingdom youth broom",
  "airport spider actress weird shaft blame urge rigid family there spike kidney",
  "raven fiction skirt identify chapter resource also cake total cluster finish rely",
  "shoe sense enjoy when session rack afford profit have art parrot film",
  "install define mystery visit dad text panel record provide breeze rug jewel",
  "unlock achieve analyst race differ rib shuffle fruit record plate mind catch",
  "current warfare rapid employ position vintage apple ripple junk shy high health",
  "cake hazard suggest refuse clump hello belt suggest clever cable guilt recycle",
  "review salon antenna zone globe cancel typical nice fluid avoid resemble velvet",
  "major proud orbit spoil chair tomato surprise idea disorder brown vivid gain",
  "that impose catch moral van forward snake border slush panda river novel",
  "antique pretty define produce rubber copy window slender invite spike park distance",
  "jar ankle antenna draft seminar cloud girl armed soul fun ginger tool",
  "young toast humble stone feel auction salute also fatigue become sword food",
  "river garlic slogan roof moment safe rifle toy vacant grab ancient clinic",
  "solve replace elder title brother treat seven forward canvas wolf misery work",
  "twin vivid suffer eternal avocado wood people where ripple version pear jealous",
  "bless demise asset direct real arm advance mean snack middle coast rigid",
  "tiny frame step issue loyal media lemon member update derive cream shy",
  "rhythm tenant laptop market globe ceiling night wrong chalk group cage learn",
  "fall craft soccer spoil peasant involve dog chest script furnace damage civil",
  "equip large lounge upset slab laptop face toast power trial about sweet",
  "confirm square detail borrow hurt welcome end power lamp perfect gold dose",
  "avoid design believe custom squirrel possible fix raise real van eight scorpion",
  "north picture pink pioneer material key forum often floor indicate effort corn",
  "ivory use scrap trap protect obvious foil earn need inhale element fragile",
  "boss rural penalty east genius have boring great toss remove zero planet",
  "bonus involve tornado vote donor boss buzz sister two elegant dizzy tuition",
  "gloom blast staff palace tooth novel citizen design adult double wide door",
  "reject argue seven candy nominee cup pizza kingdom real problem occur tuition",
  "write pretty talent pair hair wage wet service trigger demise desk ridge",
  "basket bubble chicken reward grape capital smoke decrease reduce deposit dynamic neutral",
  "pear update recall burst cost leader tuition rug degree because radar marble",
  "lab lemon smoke dismiss fashion area hobby melody supreme crowd chase club",
  "cook absurd two believe must horse insect scale skin jealous abstract magic",
  "obtain force relax index category surface scrap accuse random cabbage pet fee",
  "hurt option caution remove film wise shed inflict adult estate token menu",
  "summer usual vicious eight month glance mushroom mosquito biology fever scissors mystery",
  "long fee deny leisure curious horn daughter immense supreme cry casino swallow",
  "act hospital draft either swap toss lemon general jungle zero glass either",
  "enact diagram artwork zebra pull patient type clown gift imitate illness pitch",
  "delay wealth quarter under fish cat reflect ripple blanket dial mansion peanut",
  "agent note suit replace vast face popular imitate help poverty hard march",
  "dilemma host regret minimum include address output denial popular vanish muscle napkin",
  "case legal shuffle pistol town merge cargo bird force clown design merit",
  "deny stone apology jungle member speed finger update plastic upset muscle keep",
  "umbrella merge elder suffer industry can tree isolate crunch month visa fragile",
  "fruit scene clog know cradle civil six split world slogan build flip",
  "dinosaur ripple stumble praise warfare matrix viable shed fruit undo winter jungle",
  "lamp knee move zero fat health champion clerk debate recipe noise shine",
  "crisp congress panda exact arrest cart muscle amazing waste snow clown practice",
  "share garlic rent obey hunt follow skull stem upgrade mad hat real",
  "credit fuel junk various guide major follow quantum month upon repair unique",
  "nice peace stage alert essence caution cat make below dice couch police",
  "tongue table steak top ghost angle nasty staff frog child cup cream",
  "cruise guess black nominee common multiply rival delay cram depart tuition genius",
  "wisdom prepare seat gallery nice core ramp outside isolate mushroom dice scheme",
  "mass evidence second relax web ceiling mask way away silk diet certain",
  "shrimp remind palm chase start pass foil term digital advice soldier lawn",
  "auto length organ solid gauge bamboo photo walk kiwi curious success moment",
  "donate auto effort wage employ absurd fresh hello fox behave service market",
  "evil enlist letter unknown bar animal length ecology dose recipe car loop",
  "onion erase ship silly bundle pepper clay lift collect empty pattern trim",
  "enter minor ethics affair easily dinner enroll funny spy claw coyote venture",
  "abstract bounce surprise engine surge wife better taxi pottery need album style",
  "engage suggest bean boat social hundred honey summer mule drive welcome name",
  "hint sort exhibit pioneer blue squirrel lift exercise napkin pipe favorite broken",
  "snack few action loud melt stumble mercy end indoor winter ramp response",
  "practice luggage food vast song tunnel comic mistake matter idle someone harbor",
  "debris coconut sell nature chunk route ecology dinner scale bronze identify orchard",
  "wheat allow middle path art depth deny sweet crash roast embark park",
  "actress prevent scene enhance diary rhythm build noble cliff wagon runway state",
  "mad reject strike vote egg lunar net budget recycle alley girl sponsor",
  "toast hazard axis ride lyrics hello citizen brick pyramid enough universe return",
  "main guide casino arrow verify public huge tag neither addict defense catch",
  "question lazy black surround code attend frequent rocket target evidence waste ivory",
  "alter addict wisdom evolve rain siege mirror plate adjust kingdom youth broom",
  "airport spider actress weird shaft blame urge rigid family there spike kidney",
  "raven fiction skirt identify chapter resource also cake total cluster finish rely",
  "shoe sense enjoy when session rack afford profit have art parrot film",
  "install define mystery visit dad text panel record provide breeze rug jewel",
  "unlock achieve analyst race differ rib shuffle fruit record plate mind catch",
  "current warfare rapid employ position vintage apple ripple junk shy high health",
  "cake hazard suggest refuse clump hello belt suggest clever cable guilt recycle",
  "review salon antenna zone globe cancel typical nice fluid avoid resemble velvet",
  "major proud orbit spoil chair tomato surprise idea disorder brown vivid gain",
  "that impose catch moral van forward snake border slush panda river novel",
  "antique pretty define produce rubber copy window slender invite spike park distance",
  "jar ankle antenna draft seminar cloud girl armed soul fun ginger tool",
  "young toast humble stone feel auction salute also fatigue become sword food",
  "river garlic slogan roof moment safe rifle toy vacant grab ancient clinic",
  "solve replace elder title brother treat seven forward canvas wolf misery work",
  "twin vivid suffer eternal avocado wood people where ripple version pear jealous",
  "bless demise asset direct real arm advance mean snack middle coast rigid",
  "tiny frame step issue loyal media lemon member update derive cream shy",
  "rhythm tenant laptop market globe ceiling night wrong chalk group cage learn",
  "fall craft soccer spoil peasant involve dog chest script furnace damage civil",
  "equip large lounge upset slab laptop face toast power trial about sweet",
  "confirm square detail borrow hurt welcome end power lamp perfect gold dose",
  "avoid design believe custom squirrel possible fix raise real van eight scorpion",
  "north picture pink pioneer material key forum often floor indicate effort corn",
  "ivory use scrap trap protect obvious foil earn need inhale element fragile",
  "boss rural penalty east genius have boring great toss remove zero planet",
  "bonus involve tornado vote donor boss buzz sister two elegant dizzy tuition",
  "gloom blast staff palace tooth novel citizen design adult double wide door",
  "reject argue seven candy nominee cup pizza kingdom real problem occur tuition",
  "write pretty talent pair hair wage wet service trigger demise desk ridge",
  "basket bubble chicken reward grape capital smoke decrease reduce deposit dynamic neutral",
  "pear update recall burst cost leader tuition rug degree because radar marble",
  "lab lemon smoke dismiss fashion area hobby melody supreme crowd chase club",
  "cook absurd two believe must horse insect scale skin jealous abstract magic",
  "obtain force relax index category surface scrap accuse random cabbage pet fee",
  "hurt option caution remove film wise shed inflict adult estate token menu",
  "summer usual vicious eight month glance mushroom mosquito biology fever scissors mystery",
  "long fee deny leisure curious horn daughter immense supreme cry casino swallow",
  "act hospital draft either swap toss lemon general jungle zero glass either",
  "enact diagram artwork zebra pull patient type clown gift imitate illness pitch",
  "delay wealth quarter under fish cat reflect ripple blanket dial mansion peanut",
  "agent note suit replace vast face popular imitate help poverty hard march",
  "dilemma host regret minimum include address output denial popular vanish muscle napkin",
  "case legal shuffle pistol town merge cargo bird force clown design merit",
  "deny stone apology jungle member speed finger update plastic upset muscle keep",
  "umbrella merge elder suffer industry can tree isolate crunch month visa fragile",
  "fruit scene clog know cradle civil six split world slogan build flip",
  "dinosaur ripple stumble praise warfare matrix viable shed fruit undo winter jungle",
  "lamp knee move zero fat health champion clerk debate recipe noise shine",
  "crisp congress panda exact arrest cart muscle amazing waste snow clown practice",
  "share garlic rent obey hunt follow skull stem upgrade mad hat real",
  "credit fuel junk various guide major follow quantum month upon repair unique",
  "nice peace stage alert essence caution cat make below dice couch police",
  "tongue table steak top ghost angle nasty staff frog child cup cream",
  "cruise guess black nominee common multiply rival delay cram depart tuition genius",
  "wisdom prepare seat gallery nice core ramp outside isolate mushroom dice scheme",
  "mass evidence second relax web ceiling mask way away silk diet certain",
  "shrimp remind palm chase start pass foil term digital advice soldier lawn",
  "auto length organ solid gauge bamboo photo walk kiwi curious success moment",
  "donate auto effort wage employ absurd fresh hello fox behave service market",
  "evil enlist letter unknown bar animal length ecology dose recipe car loop",
  "onion erase ship silly bundle pepper clay lift collect empty pattern trim",
  "enter minor ethics affair easily dinner enroll funny spy claw coyote venture",
  "abstract bounce surprise engine surge wife better taxi pottery need album style",
  "engage suggest bean boat social hundred honey summer mule drive welcome name",
  "hint sort exhibit pioneer blue squirrel lift exercise napkin pipe favorite broken",
  "snack few action loud melt stumble mercy end indoor winter ramp response",
  "practice luggage food vast song tunnel comic mistake matter idle someone harbor",
  "debris coconut sell nature chunk route ecology dinner scale bronze identify orchard",
  "wheat allow middle path art depth deny sweet crash roast embark park",
  "actress prevent scene enhance diary rhythm build noble cliff wagon runway state",
  "mad reject strike vote egg lunar net budget recycle alley girl sponsor",
  "toast hazard axis ride lyrics hello citizen brick pyramid enough universe return",
  "main guide casino arrow verify public huge tag neither addict defense catch",
  "question lazy black surround code attend frequent rocket target evidence waste ivory",
  "alter addict wisdom evolve rain siege mirror plate adjust kingdom youth broom",
  "airport spider actress weird shaft blame urge rigid family there spike kidney",
  "raven fiction skirt identify chapter resource also cake total cluster finish rely",
  "shoe sense enjoy when session rack afford profit have art parrot film",
  "install define mystery visit dad text panel record provide breeze rug jewel",
  "unlock achieve analyst race differ rib shuffle fruit record plate mind catch",
  "current warfare rapid employ position vintage apple ripple junk shy high health",
  "cake hazard suggest refuse clump hello belt suggest clever cable guilt recycle",
  "review salon antenna zone globe cancel typical nice fluid avoid resemble velvet",
  "major proud orbit spoil chair tomato surprise idea disorder brown vivid gain",
  "that impose catch moral van forward snake border slush panda river novel",
  "antique pretty define produce rubber copy window slender invite spike park distance",
  "jar ankle antenna draft seminar cloud girl armed soul fun ginger tool",
  "young toast humble stone feel auction salute also fatigue become sword food",
  "river garlic slogan roof moment safe rifle toy vacant grab ancient clinic",
  "solve replace elder title brother treat seven forward canvas wolf misery work",
  "twin vivid suffer eternal avocado wood people where ripple version pear jealous",
  "bless demise asset direct real arm advance mean snack middle coast rigid",
  "tiny frame step issue loyal media lemon member update derive cream shy",
  "rhythm tenant laptop market globe ceiling night wrong chalk group cage learn",
  "fall craft soccer spoil peasant involve dog chest script furnace damage civil",
  "equip large lounge upset slab laptop face toast power trial about sweet",
  "confirm square detail borrow hurt welcome end power lamp perfect gold dose",
  "avoid design believe custom squirrel possible fix raise real van eight scorpion",
  "north picture pink pioneer material key forum often floor indicate effort corn",
  "ivory use scrap trap protect obvious foil earn need inhale element fragile",
  "boss rural penalty east genius have boring great toss remove zero planet",
  "bonus involve tornado vote donor boss buzz sister two elegant dizzy tuition",
  "gloom blast staff palace tooth novel citizen design adult double wide door",
  "reject argue seven candy nominee cup pizza kingdom real problem occur tuition",
  "write pretty talent pair hair wage wet service trigger demise desk ridge",
  "basket bubble chicken reward grape capital smoke decrease reduce deposit dynamic neutral",
  "pear update recall burst cost leader tuition rug degree because radar marble",
  "lab lemon smoke dismiss fashion area hobby melody supreme crowd chase club",
  "cook absurd two believe must horse insect scale skin jealous abstract magic",
  "obtain force relax index category surface scrap accuse random cabbage pet fee",
  "hurt option caution remove film wise shed inflict adult estate token menu",
  "summer usual vicious eight month glance mushroom mosquito biology fever scissors mystery",
  "long fee deny leisure curious horn daughter immense supreme cry casino swallow",
  "act hospital draft either swap toss lemon general jungle zero glass either",
  "enact diagram artwork zebra pull patient type clown gift imitate illness pitch",
  "delay wealth quarter under fish cat reflect ripple blanket dial mansion peanut",
  "agent note suit replace vast face popular imitate help poverty hard march",
  "dilemma host regret minimum include address output denial popular vanish muscle napkin",
  "case legal shuffle pistol town merge cargo bird force clown design merit",
  "deny stone apology jungle member speed finger update plastic upset muscle keep",
  "umbrella merge elder suffer industry can tree isolate crunch month visa fragile",
  "fruit scene clog know cradle civil six split world slogan build flip",
  "dinosaur ripple stumble praise warfare matrix viable shed fruit undo winter jungle",
  "lamp knee move zero fat health champion clerk debate recipe noise shine",
  "crisp congress panda exact arrest cart muscle amazing waste snow clown practice",
  "share garlic rent obey hunt follow skull stem upgrade mad hat real",
  "credit fuel junk various guide major follow quantum month upon repair unique",
  "nice peace stage alert essence caution cat make below dice couch police",
  "tongue table steak top ghost angle nasty staff frog child cup cream",
  "cruise guess black nominee common multiply rival delay cram depart tuition genius",
  "wisdom prepare seat gallery nice core ramp outside isolate mushroom dice scheme",
  "mass evidence second relax web ceiling mask way away silk diet certain",
  "shrimp remind palm chase start pass foil term digital advice soldier lawn",
  "auto length organ solid gauge bamboo photo walk kiwi curious success moment",
  "donate auto effort wage employ absurd fresh hello fox behave service market",
  "evil enlist letter unknown bar animal length ecology dose recipe car loop",
  "onion erase ship silly bundle pepper clay lift collect empty pattern trim",
  "enter minor ethics affair easily dinner enroll funny spy claw coyote venture",
  "abstract bounce surprise engine surge wife better taxi pottery need album style",
  "engage suggest bean boat social hundred honey summer mule drive welcome name",
  "hint sort exhibit pioneer blue squirrel lift exercise napkin pipe favorite broken",
  "snack few action loud melt stumble mercy end indoor winter ramp response",
  "practice luggage food vast song tunnel comic mistake matter idle someone harbor",
  "debris coconut sell nature chunk route ecology dinner scale bronze identify orchard",
  "wheat allow middle path art depth deny sweet crash roast embark park",
  "actress prevent scene enhance diary rhythm build noble cliff wagon runway state",
  "mad reject strike vote egg lunar net budget recycle alley girl sponsor",
  "toast hazard axis ride lyrics hello citizen brick pyramid enough universe return",
  "main guide casino arrow verify public huge tag neither addict defense catch",
  "question lazy black surround code attend frequent rocket target evidence waste ivory",
  "alter addict wisdom evolve rain siege mirror plate adjust kingdom youth broom",
  "airport spider actress weird shaft blame urge rigid family there spike kidney",
  "raven fiction skirt identify chapter resource also cake total cluster finish rely",
  "shoe sense enjoy when session rack afford profit have art parrot film",
  "install define mystery visit dad text panel record provide breeze rug jewel",
  "unlock achieve analyst race differ rib shuffle fruit record plate mind catch",
  "current warfare rapid employ position vintage apple ripple junk shy high health",
  "cake hazard suggest refuse clump hello belt suggest clever cable guilt recycle",
  "review salon antenna zone globe cancel typical nice fluid avoid resemble velvet",
  "major proud orbit spoil chair tomato surprise idea disorder brown vivid gain",
  "that impose catch moral van forward snake border slush panda river novel",
  "antique pretty define produce rubber copy window slender invite spike park distance",
  "jar ankle antenna draft seminar cloud girl armed soul fun ginger tool",
  "young toast humble stone feel auction salute also fatigue become sword food",
  "river garlic slogan roof moment safe rifle toy vacant grab ancient clinic",
  "solve replace elder title brother treat seven forward canvas wolf misery work",
];

export const getRandomText = (numChars) => {
  const randomElement = randomTextArray?.[Math.floor(Math.random() * 420)];
  return randomElement?.slice(0, numChars);
};
