import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { useEffect, useMemo } from "react";
import { useSettingsContext } from "../settings";

const ParticlesComponent = ({ id }) => {
  const settings = useSettingsContext();
  const themeMode = settings?.themeMode;
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    });
  }, []);

  const options = useMemo(() => {
    const isDarkMode = themeMode === "dark";
    return {
      background: {
        color: {
          value: isDarkMode ? "#0D1117" : "#F6F9FC",
        },
      },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "repulse",
          },
          onHover: {
            enable: true,
            mode: "grab",
          },
        },
        modes: {
          push: {
            distance: 200,
            duration: 15,
          },
          grab: {
            distance: 150,
          },
        },
      },
      particles: {
        color: {
          value: isDarkMode ? "#C0C0C0" : "#404D6A",
        },
        links: {
          color: isDarkMode ? "#FFFFFF" : "#000000",
          opacity: 0.5,
          distance: 150,
          enable: true,
          width: 1.6,
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: true,
          speed: 4,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 180,
        },
        opacity: {
          value: 1.0,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 3 },
        },
      },
      detectRetina: true,
    };
  }, [themeMode]);

  return <Particles id={id} options={options} />;
};

export default ParticlesComponent;
