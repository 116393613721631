import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { HEADER } from "../config-layout";

export default function Main({ children, sx, ...other }) {
  return (
    <Box
      component="main"
      sx={{
        position: "relative",
        minHeight: 1,
        display: "flex",
        flexDirection: "column",
        pt: `${HEADER.H_MOBILE + 24}px`,
        pb: 10,
        ...{
          pt: `${HEADER.H_MOBILE * 1.5 + 50}px`,
          pb: 2,
        },
        height: "100vh",
      }}
    >
      {children}
    </Box>
  );
}

Main.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};
